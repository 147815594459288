.Home {
    text-align: center;
}

@keyframes textReveal {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.text-reveal {
    display: inline-block;
}

.text-reveal span {
    display: inline-block;
    opacity: 0;
    animation-name: textReveal;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.card {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.4s, transform 0.4s;
}

.slide-in {
    opacity: 1;
    transform: translateX(0);
}